<template>
  <v-container class="fill-height">
    <iframe
      id="entreprise-frame"
      :src="pageUrl"
      sandbox="allow-scripts allow-same-origin allow-top-navigation"
      allowTransparency="true"
      scrolling="no"
      frameborder="0"
      height="100%"
      width="100%"
    >
    </iframe>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "../../event-bus";
import { PORTAL_CONFIG } from "../../config";

export default {
  name: "Entreprise",
  data() {
    return {
      entityId: null,
      entrepriseId: null
    };
  },
  mounted() {
    this.entityId = this.$route.params.id;
    EventBus.$on("entreprise", data => {
      this.entrepriseId = data.eID;
      this.reloadEntrepriseId();
    });
    const iframe = document.getElementById("entreprise-frame");
    const interval = 3 * 60 * 60 * 1000;

    setInterval(() => {
      iframe.contentWindow.location.reload();
    }, interval);
  },
  computed: {
    ...mapGetters(["getUUID"]),
    pageUrl() {
      const link = `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_DATA}/${this.entityId}/ecran.html`;
      return this.entrepriseId ? `${link}#frame${this.entrepriseId}` : link;
    }
  },
  methods: {
    reloadEntrepriseId() {
      setTimeout(() => {
        this.entrepriseId = null;
      }, 30 * 1000);
    }
  },
  destroyed() {
    // Destroy event listening
    EventBus.$off("entreprise");
  }
};
</script>

<style scoped>
@-moz-document url-prefix() {
  /* Disable scrollbar Firefox */
  html {
    scrollbar-width: none;
  }
}
body {
  margin: 0; /* remove default margin */
  scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
  -ms-overflow-style: none; /* Disable scrollbar IE 10+ */
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
}
</style>
